import React from 'react'
import { Icon, Menu, MenuItem } from '@blueprintjs/core'
import { useSegmentationPriorities } from '../../contexts/SegmentationPrioritiesProvider'

/**
 * SegmentationsSelector renders a menu of available segmentations.
 *
 * It sorts segmentations based on score and displays a warning if scores are outdated.
 *
 * @param {Object} props
 * @param {Function} props.selectSegmentation - Callback when a segmentation is selected.
 * @param {Object} props.selectedSegmentation - Currently selected segmentation.
 * @returns {JSX.Element} The rendered menu.
 */
export function SegmentationsSelector({
  selectSegmentation,
  selectedSegmentation,
}) {
  const { segmentations, segmentationsScores, needsRecalculationFlag } =
    useSegmentationPriorities()

  // Enhance segmentations with their respective score, if available.
  const segmentationsWithScore = segmentations.map(seg => {
    const matchingScoreObj = segmentationsScores?.find(
      score => score.id === seg.id
    )
    return {
      ...seg,
      segmentationScore: matchingScoreObj
        ? matchingScoreObj.segmentation_score
        : null,
    }
  })

  // Sort segmentations so that those with valid scores are displayed first.
  const sortedSegmentations = segmentationsWithScore.sort((a, b) => {
    if (a.segmentationScore === null && b.segmentationScore !== null) return 1
    if (a.segmentationScore !== null && b.segmentationScore === null) return -1
    if (a.segmentationScore !== null && b.segmentationScore !== null) {
      return b.segmentationScore - a.segmentationScore
    }
    return 0
  })

  return (
    <Menu style={{ height: '100%', maxHeight: '100%', overflowY: 'auto' }}>
      {/* Show a warning if segmentation scores are outdated */}
      {needsRecalculationFlag && segmentationsScores.length > 0 && (
        <MenuItem
          text={
            <span style={{ color: 'orange', fontStyle: 'italic' }}>
              Segmentation scores need to be recalculated{' '}
              <Icon icon="warning-sign" />
            </span>
          }
          disabled={true}
        />
      )}
      {sortedSegmentations.map(segmentation => (
        <MenuItem
          key={segmentation.id}
          text={segmentation.variable.name}
          // Display score as a percentage if available.
          label={
            segmentation.segmentationScore !== null ? (
              <span
                style={{
                  color: needsRecalculationFlag ? 'orange' : 'inherit',
                }}
              >
                {(segmentation.segmentationScore * 100).toFixed(3)}%
              </span>
            ) : (
              ''
            )
          }
          active={
            selectedSegmentation && selectedSegmentation.id === segmentation.id
          }
          onClick={() => selectSegmentation(segmentation)}
        />
      ))}
    </Menu>
  )
}
