import React from 'react'
import './SegmentationExplorerDescription.css'
import { PLOTS_COLORS } from './Colors'
import { GeneralInformationCard } from './GeneralInformationCard'
import { SegmentDistributionChart } from './SegmentDistributionChart'

/**
 * SegmentationExplorerDescription component renders information about a segmentation along with charts.
 * @param {Object} props - Component props.
 * @param {Object} props.segmentation - The segmentation data containing fields like algo, id, size, silhouette_score, inputs, seed, variable, and is_imported.
 */
export function SegmentationExplorerDescription({ segmentation }) {
  if (!segmentation) {
    return <div>No segmentations .</div>
  }

  const {
    algo,
    id,
    size,
    silhouette_score,
    inputs,
    seed,
    variable,
    is_imported,
  } = segmentation

  const segmentCounts = variable.data.reduce((acc, segment) => {
    acc[segment] = (acc[segment] || 0) + 1
    return acc
  }, {})
  const segmentDistribution = Object.entries(segmentCounts).map(
    ([segment, count]) => ({
      name: `Segment ${segment}`,
      value: count,
      percentage: ((count / variable.data.length) * 100).toFixed(2),
    })
  )

  const PLOT_COLORS = PLOTS_COLORS

  const numberOfSegments = size
  const averagePopulation =
    numberOfSegments !== 0 ? variable.data.length / numberOfSegments : 0

  return (
    <div className="segmentation-explorer-description-div">
      <GeneralInformationCard
        algo={algo}
        size={size}
        silhouette_score={silhouette_score}
        inputs={inputs}
        seed={seed}
        is_imported={is_imported}
        id={id}
      />
      <SegmentDistributionChart
        segmentDistribution={segmentDistribution}
        averagePopulation={averagePopulation}
        COLORS={PLOT_COLORS}
      />
    </div>
  )
}
