import React from 'react'
import { Card } from '@blueprintjs/core'

/**
 * GeneralInformationCard component renders general segmentation information.
 * @param {Object} props - The component props.
 * @param {string} props.algo - Algorithm name.
 * @param {number} props.size - Number of segments.
 * @param {number} props.silhouette_score - Average silhouette score.
 * @param {Array} props.inputs - Array of input objects.
 * @param {number} props.seed - Random seed used for segmentation.
 * @param {boolean} props.is_imported - Flag indicating if segmentation was imported.
 * @param {string|number} props.id - Unique identifier for the segmentation.
 */
export function GeneralInformationCard({
  algo,
  size,
  silhouette_score,
  inputs,
  seed,
  is_imported,
  id,
}) {
  return (
    <Card className="segmentation-explorer-general-information-card">
      <h3>General Information</h3>
      <p>
        <strong>Algorithm:</strong> {algo}
      </p>
      <p>
        <strong>Segments:</strong> {size}
      </p>
      <p>
        <strong>Avg. Silhouette Score:</strong> {silhouette_score.toFixed(3)}
      </p>
      <p>
        <strong>Inputs:</strong> {inputs.length}
      </p>
      <p>
        <strong>Seed:</strong> {seed}
      </p>
      <p>
        <strong>Is imported:</strong> {is_imported ? 'Yes' : 'No'}
      </p>
      <p>
        <strong>Solution ID:</strong> {id}
      </p>
    </Card>
  )
}
