import React, { useState, useEffect } from 'react'
import { Card, InputGroup, Button, Menu, MenuItem } from '@blueprintjs/core'
import { Popover2, Tooltip2 } from '@blueprintjs/popover2'
import { useSegmentationPriorities } from '../../contexts/SegmentationPrioritiesProvider'
import { InputsTable } from './InputsTable'

/**
 * Renders a menu item to select/deselect all priorities.
 *
 * @param {Object} props
 * @param {Array} props.selectedPriorities - Currently selected priorities.
 * @param {Function} props.setSelectedPriorities - Setter for selected priorities.
 * @returns {JSX.Element} The rendered menu item.
 */
function SelectAllPrioritiesMenuItem({
  selectedPriorities,
  setSelectedPriorities,
}) {
  const { segmentationPriorities } = useSegmentationPriorities()
  const handleToggleSelectAll = () => {
    if (selectedPriorities.length === segmentationPriorities.length) {
      setSelectedPriorities([])
    } else {
      setSelectedPriorities(segmentationPriorities)
    }
  }
  return (
    <MenuItem
      key="all"
      text="All Priorities"
      onClick={handleToggleSelectAll}
      icon={
        selectedPriorities.length === segmentationPriorities.length
          ? 'tick'
          : 'blank'
      }
      shouldDismissPopover={false}
    />
  )
}

/**
 * Renders a menu item for a single priority.
 *
 * @param {Object} props
 * @param {Object} props.priority - The priority represented.
 * @param {Array} props.selectedPriorities - Already selected priorities.
 * @param {Function} props.setSelectedPriorities - Setter for selected priorities.
 * @returns {JSX.Element} The rendered menu item.
 */
function SelectPriorityMenuItem({
  priority,
  selectedPriorities,
  setSelectedPriorities,
}) {
  const handleToggleSelect = () => {
    if (selectedPriorities.some(p => p.id === priority.id)) {
      setSelectedPriorities(
        selectedPriorities.filter(p => p.id !== priority.id)
      )
    } else {
      setSelectedPriorities([...selectedPriorities, priority])
    }
  }
  return (
    <MenuItem
      text={priority.name || 'Unnamed Priority'}
      onClick={handleToggleSelect}
      icon={
        selectedPriorities.some(p => p.id === priority.id) ? 'tick' : 'blank'
      }
      shouldDismissPopover={false}
    />
  )
}

/**
 * Renders an input group for filtering inputs.
 *
 * @param {Object} props
 * @param {string} props.searchTerm - The current search term.
 * @param {Function} props.setSearchTerm - Setter for the search term.
 * @returns {JSX.Element} The rendered FilterSearchInput.
 */
function FilterSearchInput({ searchTerm, setSearchTerm }) {
  return (
    <InputGroup
      placeholder="Search inputs..."
      value={searchTerm}
      onChange={e => setSearchTerm(e.target.value)}
      leftIcon="search"
      rightElement={
        <Button
          title="Clear Search"
          icon="cross"
          onClick={() => setSearchTerm('')}
          intent="danger"
          minimal={true}
        />
      }
      className="segmentation-weights-search-group"
    />
  )
}

/**
 * Renders a button that opens a filter menu by priority.
 *
 * @param {Object} props
 * @param {Array} props.filteredPriorities - Currently filtered priorities.
 * @param {Function} props.setFilteredPriorities - Setter for filtered priorities.
 * @returns {JSX.Element} The rendered FilterByPriorityButton.
 */
function FilterByPriorityButton({ filteredPriorities, setFilteredPriorities }) {
  const { segmentationPriorities } = useSegmentationPriorities()
  const renderFilterMenu = () => {
    if (segmentationPriorities.length === 0) {
      return (
        <MenuItem
          text="No Buckets created or Inputs Selected."
          disabled={true}
        />
      )
    }
    return [
      <SelectAllPrioritiesMenuItem
        selectedPriorities={filteredPriorities}
        setSelectedPriorities={setFilteredPriorities}
        key={'all'}
      />,
      ...segmentationPriorities.map((priority, index) => {
        if (priority.name === undefined) {
          priority.name = 'Priority ' + index + 1
        }
        return (
          <SelectPriorityMenuItem
            key={priority.id}
            priority={priority}
            selectedPriorities={filteredPriorities}
            setSelectedPriorities={setFilteredPriorities}
          />
        )
      }),
    ]
  }
  return (
    <Tooltip2 content="Filter by Priority" placement="top-end">
      <Popover2 content={<Menu>{renderFilterMenu()}</Menu>} position="bottom">
        <Button
          icon="filter"
          minimal={false}
          className="filter-button-segmentation-weights"
        />
      </Popover2>
    </Tooltip2>
  )
}

/**
 * Renders a card for configuring input variables.
 *
 * @param {Object} props
 * @param {Array} props.selectedInputs - Currently selected inputs.
 * @param {Function} props.setSelectedInputs - Setter for selected inputs.
 * @returns {JSX.Element} The rendered InputsConfigurationCard.
 */
export function InputsConfigurationCard({ selectedInputs, setSelectedInputs }) {
  const { availableInputs } = useSegmentationPriorities()
  const [filteredInputs, setFilteredInputs] = useState([])
  const [filteredPriorities, setFilteredPriorities] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  // Filter inputs whenever the dependencies change.
  useEffect(() => {
    let inputs = availableInputs || []
    if (searchTerm.trim() !== '') {
      inputs = inputs.filter(
        input =>
          (input.name &&
            input.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
          (input.label &&
            input.label.toLowerCase().includes(searchTerm.toLowerCase()))
      )
    }
    if (filteredPriorities.length === 0) {
      setFilteredInputs(inputs)
    } else {
      const filteredPrioritiesIds = filteredPriorities.map(p => p.id)
      const inputsFilteredByPriority = inputs.filter(input =>
        filteredPrioritiesIds.some(id => input.priorityId === id)
      )
      setFilteredInputs(inputsFilteredByPriority)
    }
  }, [availableInputs, searchTerm, filteredPriorities])

  return (
    <Card className="segmentation-weights-card-container">
      <div className="segmentation-weights-search-container">
        {/* Pass the search term, its setter, and the clear function */}
        <FilterSearchInput
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />
        <FilterByPriorityButton
          setFilteredPriorities={setFilteredPriorities}
          filteredPriorities={filteredPriorities}
        />
      </div>
      <h3>Input Variables ({filteredInputs.length})</h3>
      <div className="segmentation-input-variables">
        <InputsTable
          filteredInputs={filteredInputs}
          selectedInputs={selectedInputs}
          setSelectedInputs={setSelectedInputs}
        />
      </div>
    </Card>
  )
}
