import React, { useState } from 'react'
import { Card, Button } from '@blueprintjs/core'
import EChartsReact from 'echarts-for-react'
import './SegmentationExplorerChart.css'
import { PLOTS_COLORS } from './Colors'
import { HelpDrawerSilhouettePlot } from './HelpDrawer'

/**
 * SegmentationExplorerChart component displays the Silhouette Plot for the selected segmentation.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.segmentation - The selected segmentation data.
 * @returns {JSX.Element} The rendered Silhouette Plot.
 */
export function SegmentationExplorerCharts({ segmentation }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const handleDrawerOpen = () => setIsDrawerOpen(true)
  const handleDrawerClose = () => setIsDrawerOpen(false)

  let segmentAssignments = []
  let silhouetteData = {}
  let avgSilhouetteScore = 0

  if (
    segmentation &&
    segmentation.variable &&
    segmentation.variable.data &&
    segmentation.silhouette_samples
  ) {
    segmentAssignments = segmentation.variable.data
    const silhouetteSamples = segmentation.silhouette_samples

    // Use silhouette scores for each segment
    silhouetteData = {}
    segmentAssignments.forEach((segmentLabel, index) => {
      if (!silhouetteData[segmentLabel]) {
        silhouetteData[segmentLabel] = []
      }
      silhouetteData[segmentLabel].push(
        parseFloat(silhouetteSamples[index].toFixed(3))
      )
    })

    // Sort silhouette scores within each segment
    Object.keys(silhouetteData).forEach(segmentLabel => {
      silhouetteData[segmentLabel] = silhouetteData[segmentLabel].sort(
        (a, b) => a - b
      )
    })

    // Compute the average silhouette score
    const allScores = Object.values(silhouetteData).flat()
    avgSilhouetteScore =
      allScores.length > 0
        ? allScores.reduce((acc, score) => acc + score, 0) / allScores.length
        : 0
  }

  const segments = Object.keys(silhouetteData).map(Number)

  /**
   * Function to assign colors to segments
   * @param {number|string} segment - segment label
   * @returns {string} - Color code
   */
  const getColorBySegment = segment => {
    const COLORS = PLOTS_COLORS
    // Map segment labels to indices to ensure consistent color assignment
    const segmentIndex = segments.indexOf(Number(segment))
    return COLORS[segmentIndex % COLORS.length]
  }

  /**
   * Function to generate the chart options for the Silhouette Plot
   * @returns {Object} - ECharts options object
   */
  const getSilhouetteChartOptions = () => {
    // Re-index the silhouette data after sorting and grouping by segment
    let reIndexedData = []
    let yOffset = 0

    segments.forEach(segment => {
      const segmentData = silhouetteData[segment]
      segmentData.forEach((value, index) => {
        reIndexedData.push({
          value,
          segment,
          yOffset,
        })
      })

      yOffset += segmentData.length + 5
    })

    // Prepare series data for the bar chart
    const data = reIndexedData.map((item, index) => ({
      value: item.value,
      yAxis: item.yOffset + index,
      itemStyle: {
        color: getColorBySegment(item.segment),
      },
      name: `Segment ${item.segment}`,
    }))

    return {
      tooltip: {
        show: true,
        trigger: 'item',
        formatter: params => {
          if (params.componentType === 'series') {
            return `${params.data.name}<br/>Silhouette: ${params.value}`
          }
          return null
        },
      },
      xAxis: {
        type: 'value',
        name: 'Silhouette \nScore',
        nameLocation: 'middle',
        nameGap: 30,
      },
      yAxis: {
        type: 'category',
        name: 'Data Points',
        axisLabel: {
          show: false, // Hide y-axis labels for better visualization
        },
        splitLine: {
          show: false, // Hide y-axis split lines
        },
        axisTick: {
          show: false, // Hide y-axis ticks
        },
        data: reIndexedData.map((_, index) => index),
      },
      toolbox: {
        show: true,
        feature: {
          dataView: { show: false, readOnly: false },
          saveAsImage: { show: true },
        },
      },
      series: [
        {
          name: 'Silhouette Scores',
          type: 'bar',
          data,
          barCategoryGap: '50%',
          label: {
            show: false,
          },
          markLine: {
            symbol: 'none',
            silent: true,
            data: [
              {
                name: 'Average Silhouette Score',
                xAxis: avgSilhouetteScore,
                lineStyle: {
                  color: 'red',
                  type: 'dashed',
                },
                label: {
                  formatter: `Avg. Score: ${avgSilhouetteScore.toFixed(3)}`,
                  position: 'end',
                  color: 'red',
                },
              },
            ],
          },
        },
      ],
    }
  }

  return (
    <div>
      <div className="chart-title">
        <h2>
          Silhouette Plot - {segmentation ? segmentation.variable.name : ''}
          <Button icon="help" minimal={true} onClick={handleDrawerOpen} />
        </h2>
      </div>
      <Card className="segmentation-explorer-chart-card">
        <EChartsReact
          option={getSilhouetteChartOptions()}
          className="segmentation-explorer-chart-echart"
          style={{ height: '450px', width: '80%' }}
        />
      </Card>
      <HelpDrawerSilhouettePlot
        isOpen={isDrawerOpen}
        onClose={handleDrawerClose}
      />
    </div>
  )
}
