import React from 'react'
import { Card } from '@blueprintjs/core'
import EChartsReact from 'echarts-for-react'

/**
 * SegmentDistributionChart component renders a chart displaying segment distributions.
 * @param {Object} props - The component props.
 * @param {Array} props.segmentDistribution - Array of segment objects with keys such as name, value, and percentage.
 * @param {Array} props.COLORS - Array of colors used to style chart bars.
 */
export function SegmentDistributionChart({ segmentDistribution, COLORS }) {
  return (
    <Card className="segmentation-explorer-description-distribution-chart">
      <h3>Segments Distribution</h3>
      <EChartsReact
        className="segmentation-explorer-description-barchart"
        option={{
          tooltip: {
            trigger: 'axis',
            borderColor: '#ccc',
            axisPointer: {
              type: 'shadow',
            },
            formatter: params => {
              const { name, value } = params[0]
              const percentage = segmentDistribution.find(
                item => item.name === name
              ).percentage
              return `${name}<br/>Respondants: ${value}<br/>Percentage: ${percentage}%`
            },
          },
          toolbox: {
            show: true,
            feature: {
              dataView: { show: true, readOnly: false },
              saveAsImage: { show: true },
            },
          },
          xAxis: {
            type: 'category',
            data: segmentDistribution.map(item => item.name),
            axisLabel: {
              rotate: 45,
              interval: 0,
            },
          },
          yAxis: {
            type: 'value',
            name: 'Respondants',
          },
          series: [
            {
              name: 'Respondants',
              type: 'bar',
              data: segmentDistribution.map(item => ({
                value: item.value,
                percentage: item.percentage,
              })),
              itemStyle: {
                color: params => COLORS[params.dataIndex % COLORS.length],
              },
              label: {
                show: true,
                position: 'inside',
                formatter: params => `${params.data.percentage}%`,
              },
            },
          ],
        }}
      />
    </Card>
  )
}
