import { useEffect, useState, useCallback } from 'react'
import { useSurvey } from '../contexts/SurveyProvider'
import { Link } from 'react-router-dom'
import { useApi } from '../contexts/ApiProvider'
import {
  Dialog,
  DialogBody,
  HotkeysProvider,
  NonIdealState,
  Spinner,
} from '@blueprintjs/core'
import { AppToaster } from './toaster'
import { errorObjectToString } from '../ApiClient'
import { SegmentationsSelector } from './segmentationexplorer/SegmentationsSelector'
import { WeightsGroupTabs } from './segmentationexplorer/WeightsGroupTabs'
import { InfoGroupTabs } from './segmentationexplorer/InfoGroupTabs'
import { useSegmentationPriorities } from '../contexts/SegmentationPrioritiesProvider'
import { DialogNavbar } from './segmentationexplorer/DialogNavBar'

/**
 * SegmentationExplorerDialog component displays the segmentation explorer dialog.
 *
 * It manages segmentation selection, fetches extra data, and toggles between
 * info and weights views.
 *
 * @returns {JSX.Element} The rendered dialog component.
 */
export function SegmentationExplorerDialog() {
  const { setSurveyDialogOpen, surveyDialogOpen, surveyId } = useSurvey()
  const { segmentations } = useSegmentationPriorities()
  const [selectedSegmentation, setSelectedSegmentation] = useState()
  const [selectedGroupTabs, setSelectedGroupTabs] = useState('info')
  const api = useApi()

  const onClose = () => setSurveyDialogOpen()

  const isOpen = surveyDialogOpen === 'SegmentationExplorerDialog'

  /**
   * selectSegmentation fetches detailed data for a given segmentation.
   *
   * @param {Object} segmentation - The segmentation object to fetch data for.
   */
  const selectSegmentation = useCallback(
    async segmentation => {
      const response = await api.get(
        `/survey/${surveyId}/segmentation/${segmentation.id}`
      )
      if (response.ok) {
        setSelectedSegmentation(response.body)
      } else {
        const message = errorObjectToString(response.body.messages.json)
        AppToaster.show({ message, intent: 'danger', icon: 'error' })
      }
    },
    [api, surveyId]
  )

  useEffect(() => {
    if (segmentations && segmentations.length > 0) {
      selectSegmentation(segmentations[0])
    }
  }, [segmentations, selectSegmentation])

  // Switch to weights view.
  const showWeightTabs = () => {
    setSelectedGroupTabs('weights')
  }
  // Switch to info view.
  const showInfoTabs = () => {
    setSelectedGroupTabs('info')
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      title={
        <DialogNavbar
          selectedGroupTabs={selectedGroupTabs}
          showInfoTabs={showInfoTabs}
          showWeightTabs={showWeightTabs}
        />
      }
      icon="inherited-group"
      style={{ width: '90vw', height: '90vh' }}
    >
      <DialogBody style={{ height: 'calc(100% - 100px)', overflow: 'hidden' }}>
        {segmentations ? (
          segmentations.length ? (
            <div style={{ display: 'flex', height: '100%' }}>
              <div style={{ width: '25%', overflowY: 'auto' }}>
                <SegmentationsSelector
                  selectSegmentation={selectSegmentation}
                  selectedSegmentation={selectedSegmentation}
                />
              </div>
              <div
                style={{
                  width: '75%',
                  marginLeft: '20px',
                  overflow: 'hidden',
                }}
              >
                {selectedGroupTabs === 'info' ? (
                  <InfoGroupTabs selectedSegmentation={selectedSegmentation} />
                ) : (
                  <HotkeysProvider>
                    <WeightsGroupTabs />
                  </HotkeysProvider>
                )}
              </div>
            </div>
          ) : (
            <div>
              No segmentations yet.{' '}
              <Link to={`/survey/${surveyId}/grouper`} onClick={onClose}>
                Create some.
              </Link>
            </div>
          )
        ) : (
          <NonIdealState
            icon={<Spinner intent="primary" />}
            title="Loading data"
            description="Please wait while load segmentations."
          />
        )}
      </DialogBody>
    </Dialog>
  )
}