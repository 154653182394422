import React from 'react'
import { Alignment, Button, ButtonGroup, Navbar } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'
import { useSegmentationPriorities } from '../../contexts/SegmentationPrioritiesProvider'

/**
 * InfoButton renders a button to show the info view.
 *
 * @param {Object} props
 * @param {string} props.selectedGroupTabs - Current active tab.
 * @param {Function} props.showInfoTabs - Function to activate info view.
 * @returns {JSX.Element} The rendered Info button.
 */
function InfoButton({ selectedGroupTabs, showInfoTabs }) {
  const { isFetchingScores } = useSegmentationPriorities()
  return (
    <Tooltip2
      content="Displays segmentation details, inputs, and visualization."
      placement="bottom-end"
    >
      <Button
        className="show-info"
        icon="info-sign"
        text="Info"
        active={selectedGroupTabs === 'info'}
        onClick={showInfoTabs}
        disabled={isFetchingScores}
      />
    </Tooltip2>
  )
}

/**
 * SetWeightsButton renders a button to show the weights view.
 *
 * @param {Object} props
 * @param {string} props.selectedGroupTabs - Current active tab.
 * @param {Function} props.showWeightTabs - Function to activate weights view.
 * @returns {JSX.Element} The rendered Set Weights button.
 */
function SetWeightsButton({ selectedGroupTabs, showWeightTabs }) {
  return (
    <Tooltip2
      content="Customize variable weights and view segmentation scores."
      placement="bottom-end"
    >
      <Button
        className="show-weights"
        icon="lab-test"
        text="Set Weights"
        active={selectedGroupTabs === 'weights'}
        onClick={showWeightTabs}
      />
    </Tooltip2>
  )
}

/**
 * DialogNavbar renders the dialog's navigation bar with tab selection buttons.
 *
 * @param {Object} props
 * @param {string} props.selectedGroupTabs - Current active tab.
 * @param {Function} props.showInfoTabs - Function to activate info view.
 * @param {Function} props.showWeightTabs - Function to activate weights view.
 * @returns {JSX.Element} The rendered navigation bar.
 */
export function DialogNavbar({
  selectedGroupTabs,
  showInfoTabs,
  showWeightTabs,
}) {
  return (
    <div style={{ display: 'flex' }}>
      <Navbar>
        <Navbar.Group align={Alignment.LEFT}>
          Segmentation Explorer
        </Navbar.Group>
        <Navbar.Group align={Alignment.RIGHT}>
          <ButtonGroup>
            <InfoButton
              selectedGroupTabs={selectedGroupTabs}
              showInfoTabs={showInfoTabs}
            />
            <SetWeightsButton
              selectedGroupTabs={selectedGroupTabs}
              showWeightTabs={showWeightTabs}
            />
          </ButtonGroup>
        </Navbar.Group>
      </Navbar>
    </div>
  )
}
