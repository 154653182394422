import React, { useState } from 'react'
import { HTMLTable } from '@blueprintjs/core'
import { useSegmentationPriorities } from '../../contexts/SegmentationPrioritiesProvider'

/**
 * InputsTable component renders a table with input items.
 * @param {Object} props - The component props.
 * @param {Array} props.filteredInputs - Array of input objects.
 * @param {Function} props.setSelectedInputs - Function to update selected inputs.
 * @param {Array} props.selectedInputs - Array of currently selected input objects.
 */
export function InputsTable({
  filteredInputs,
  setSelectedInputs,
  selectedInputs,
}) {
  const [lastInputSelectedIndex, setLastInputSelectedIndex] = useState(null)
  const { segmentationPriorities } = useSegmentationPriorities()

  /**
   * Handles selection toggling on CTRL/Command click.
   * @param {Object} input - The input object to toggle.
   */
  const handleControlClick = input => {
    if (!selectedInputs.some(selected => selected.name === input.name)) {
      setSelectedInputs([...selectedInputs, input])
    } else {
      setSelectedInputs(
        selectedInputs.filter(selected => selected.name !== input.name)
      )
    }
  }

  /**
   * Handles range selection on SHIFT click.
   * @param {number} index - The index of the clicked input.
   */
  const handleShiftClick = index => {
    const start = Math.min(lastInputSelectedIndex, index) + 1 // Add 1 to not duplicate the first selected input
    const end = Math.max(lastInputSelectedIndex, index)
    const newSelections = filteredInputs.slice(start, end + 1)
    setSelectedInputs([...new Set([...selectedInputs, ...newSelections])])
  }

  /**
   * Handles selection on normal click.
   * @param {Object} input - The input object clicked.
   */
  const handleNormalClick = input => {
    if (!selectedInputs.some(selected => selected.name === input.name)) {
      setSelectedInputs([input])
    } else {
      setSelectedInputs([])
    }
  }

  /**
   * Handles click events on a row, delegating to the appropriate handler based on keyboard modifiers.
   * @param {Object} input - The input object clicked.
   * @param {number} index - The index of the clicked input.
   * @param {Object} event - The click event object.
   */
  const handleTagClick = (input, index, event) => {
    if (event.ctrlKey || event.metaKey) {
      handleControlClick(input)
    } else if (event.shiftKey && lastInputSelectedIndex !== null) {
      handleShiftClick(index)
    } else {
      handleNormalClick(input)
    }
    setLastInputSelectedIndex(index)
  }

  return (
    <HTMLTable
      bordered={true}
      interactive={true}
      className="tableWeightsFixHead"
    >
      <thead>
        <tr>
          <th>Input Name</th>
          <th>Input Label</th>
        </tr>
      </thead>
      <tbody>
        {filteredInputs.length > 0 ? (
          filteredInputs.map((input, index) => {
            let backgroundColor = 'transparent'

            const inputPriority = segmentationPriorities.find(
              priority => priority.id === input.priorityId
            )
            const isInputSelected = selectedInputs.some(
              selected => selected.name === input.name
            )
            if (inputPriority) {
              if (isInputSelected) {
                backgroundColor = inputPriority.darker_color
              } else {
                backgroundColor = inputPriority.color
              }
            }

            return (
              <tr
                key={input.id}
                onClick={event => handleTagClick(input, index, event)}
                className={`segmentation-weights ${
                  selectedInputs.some(selected => selected.name === input.name)
                    ? 'selected'
                    : ''
                }`}
                data-input-name={input.name}
                style={{ backgroundColor }}
              >
                <td>{input.name}</td>
                <td>{input.label}</td>
              </tr>
            )
          })
        ) : (
          <tr>
            <td colSpan="2">No inputs match your search.</td>
          </tr>
        )}
      </tbody>
    </HTMLTable>
  )
}
