import React, { useState } from 'react'
import './SegmentationExplorerWeights.css'

import { InputsConfigurationCard } from './InputsConfigurationCard'
import { WeightsConfigurationCard } from './WeightsConfigurationCard'

/**
 * SegmentationExplorerWeights component renders the weights view for segmentation priorities.
 *
 * @returns {JSX.Element} The weights view component.
 */
export function SegmentationExplorerWeights() {
  const [selectedInputs, setSelectedInputs] = useState([])
  return (
    <div className="segmentation-explorer-container">
      <InputsConfigurationCard
        selectedInputs={selectedInputs}
        setSelectedInputs={setSelectedInputs}
      />
      <WeightsConfigurationCard selectedInputs={selectedInputs} />
    </div>
  )
}
