import React, { useState } from 'react'
import { Tabs, Tab, Icon } from '@blueprintjs/core'
import { SegmentationExplorerDescription } from './SegmentationExplorerDescription'
import { SegmentationExplorerInputs } from './SegmentationExplorerInputs'
import { SegmentationExplorerCharts } from './SegmentationExplorerCharts'

/**
 * InfoGroupTabs component renders a tab view with Description, Inputs, and Silhouette Plot panels.
 *
 * @param {Object} props - Component props.
 * @param {Object} props.selectedSegmentation - The segmentation data for the selected tab.
 * @returns {JSX.Element} The rendered component.
 */
export function InfoGroupTabs({ selectedSegmentation }) {
  const [selectedTab, setSelectedTab] = useState('description')

  return (
    <Tabs
      id="infoTabs"
      selectedTabId={selectedTab}
      onChange={tabId => setSelectedTab(tabId)}
    >
      <Tab
        id="description"
        title={
          <>
            <Icon icon="properties" />
            <span style={{ marginLeft: '8px' }}>Description</span>
          </>
        }
        panel={
          <SegmentationExplorerDescription
            segmentation={selectedSegmentation}
          />
        }
      />
      <Tab
        id="inputs"
        title={
          <>
            <Icon icon="search-template" />
            <span style={{ marginLeft: '8px' }}>Inputs</span>
          </>
        }
        panel={
          <SegmentationExplorerInputs segmentation={selectedSegmentation} />
        }
      />
      <Tab
        id="silhouette-charts"
        title={
          <>
            <Icon icon="horizontal-bar-chart" />
            <span style={{ marginLeft: '8px' }}>Silhouette Plot</span>
          </>
        }
        panel={
          <SegmentationExplorerCharts segmentation={selectedSegmentation} />
        }
      />
    </Tabs>
  )
}
