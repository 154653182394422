import React, { useState, useRef } from 'react'
import { Card, InputGroup, Button, HTMLTable } from '@blueprintjs/core'
import './SegmentationExplorerInputs.css'
import { AppToaster } from '../toaster'
import { NonIdealState } from '@blueprintjs/core'
import { Tooltip2 } from '@blueprintjs/popover2'

/**
 * Renders the segmentation explorer inputs component.
 * @param {Object} props - Component props.
 * @param {Object} props.segmentation - Segmentation object including inputs.
 * @returns {JSX.Element} The rendered component.
 */
export function SegmentationExplorerInputs({ segmentation }) {
  const [searchTerm, setSearchTerm] = useState('')
  const selectedInputsRef = useRef([])

  if (!segmentation) {
    return <NonIdealState title="No Segmentation selected." icon="alert" />
  }

  const { inputs } = segmentation

  // Filter inputs if defined
  const filteredInputs = inputs.filter(
    input =>
      (input.name &&
        input.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (input.label &&
        input.label.toLowerCase().includes(searchTerm.toLowerCase()))
  )

  /**
   * Handles the event when an input row is clicked.
   * @param {Object} input - The input object that was clicked.
   */
  const handleTagClick = input => {
    if (!selectedInputsRef.current.includes(input.name)) {
      selectedInputsRef.current = [...selectedInputsRef.current, input.name]
    }
  }

  /**
   * Copies input data to the clipboard.
   * @returns {Promise<void>}
   */
  const handleCopyToClipboard = () => {
    const header = 'Input Name\tInput Label'

    const rows = inputs.map(
      input => `${input.name}\t${input.label || 'No label available'}`
    )

    const tableData = [header, ...rows].join('\n')

    return navigator.clipboard
      .writeText(tableData)
      .then(() => {
        AppToaster.show({
          message: 'Inputs have been copied to clipboard',
          intent: 'success',
        })
      })
      .catch(err => {
        AppToaster.show({
          message: 'Failed to copy inputs to clipboard',
          intent: 'danger',
        })
      })
  }

  /**
   * Clears the search term in the input search field.
   */
  const handleClearSearch = () => {
    setSearchTerm('')
  }

  return (
    <Card className="selected-segmentation-inputs-card-container">
      <div className="selected-segmentation-inputs-search-container">
        <InputGroup
          placeholder="Search inputs..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          leftIcon="search"
          rightElement={
            <Button
              alt="Clear Search"
              icon="cross"
              onClick={handleClearSearch}
              intent="danger"
              minimal={true}
            />
          }
          className="selected-segmentation-inputs-search-group"
        />
        <Tooltip2 content="Copy Inputs to clipboard" placement="top-end">
          <Button
            alt="Copy to Clipboard"
            icon="duplicate"
            onClick={handleCopyToClipboard}
            className="copy-button-selected-segmentation-inputs"
          />
        </Tooltip2>
      </div>
      <h3>Solution Input Variables ({inputs.length})</h3>
      <div className="selected-segmentation-input-variables">
        <HTMLTable striped={true} bordered={true} className="tableFixHead">
          <thead>
            <tr>
              <th>Input Name</th>
              <th>Input Label</th>
            </tr>
          </thead>
          <tbody>
            {filteredInputs.length > 0 ? (
              filteredInputs.map(input => (
                <tr
                  key={input.id}
                  onClick={() => handleTagClick(input)}
                  className="selected-segmentation-inputs-"
                >
                  <td>{input.name}</td>
                  <td>{input.label || 'No label available'}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2">No inputs match your search.</td>
              </tr>
            )}
          </tbody>
        </HTMLTable>
      </div>
    </Card>
  )
}
