import {
  createContext,
  useEffect,
  useState,
  useContext,
  useCallback,
} from 'react'
import { errorObjectToString } from '../ApiClient'
import { AppToaster } from '../components/toaster'
import { useApi } from './ApiProvider'
import { useParams } from 'react-router-dom'

/**
 * Context for the main window. Should contain all state and functions
 * that don't alter the state of the survey, but rather the state of the
 * UI, such as which dialog is open, which survey is currently selected,
 * etc.
 */
export const WindowContext = createContext()

export default function WindowProvider({ children }) {
  const [surveys, setSurveys] = useState()
  const [currentSurvey, setCurrentSurvey] = useState()
  const [dialogOpen, setDialogOpen] = useState()
  const [settings, setSettings] = useState({
    statTesting: false,
    percentages: false,
    highlighting: false,
    showIndexTabs: true,
    showMeanTabs: true,
    surveysView: 'table',
    chart: null,
  })

  const api = useApi()
  const [banner, setBanner] = useState([])
  const [stub, setStub] = useState([])
  const [loading, setLoading] = useState(false)

  const { surveyId } = useParams()

  useEffect(() => {
    ;(async () => {
      const response = await api.get('/surveys')
      setSurveys(response.ok ? response.body : null)
    })()
  }, [api])

  const addSlideToReport = useCallback(
    async (slide, reportId) => {
      setLoading(true)
      const response = await api.post(`/report/${reportId}/slide`, slide)
      if (response.ok) {
        const reportIndex = currentSurvey.reports.findIndex(
          r => r.id === reportId
        )
        setCurrentSurvey({
          ...currentSurvey,
          reports: [
            ...currentSurvey.reports.slice(0, reportIndex),
            response.body,
            ...currentSurvey.reports.slice(reportIndex + 1),
          ],
        })
      } else {
        const message = errorObjectToString(response.body.messages.json)
        AppToaster.show({
          message,
          intent: 'danger',
          icon: 'error',
        })
      }
      setLoading(false)
    },
    [api, currentSurvey, setCurrentSurvey, setLoading]
  )

  const createReport = useCallback(
    async report => {
      setLoading(true)
      const response = await api.post(`/survey/${surveyId}/report`, report)
      if (response.ok) {
        setCurrentSurvey({
          ...currentSurvey,
          reports: [...currentSurvey.reports, response.body],
        })
        return response.body
      } else {
        console.log(response)
        const message = errorObjectToString(response.body.messages.json)
        AppToaster.show({
          message,
          intent: 'danger',
          icon: 'error',
        })
      }
      setLoading(false)
    },
    [api, currentSurvey, surveyId, setCurrentSurvey, setLoading]
  )

  const deleteSlide = useCallback(
    async (slideId, reportId) => {
      setLoading(true)
      const response = await api.delete(`/slide/${slideId}`)
      if (response.ok) {
        const reportIndex = currentSurvey.reports.findIndex(
          r => r.id === reportId
        )
        setCurrentSurvey({
          ...currentSurvey,
          reports: [
            ...currentSurvey.reports.slice(0, reportIndex),
            {
              ...currentSurvey.reports[reportIndex],
              slides: currentSurvey.reports[reportIndex].slides.filter(
                s => s.id !== slideId
              ),
            },
            ...currentSurvey.reports.slice(reportIndex + 1),
          ],
        })
      } else {
        console.log(response)
        const message = `Error deleting (${response.status} - ${response?.body?.error})`
        AppToaster.show({
          message,
          intent: 'danger',
          icon: 'error',
        })
      }
      setLoading(false)
    },
    [api, currentSurvey, setCurrentSurvey, setLoading]
  )

  const describeSegment = useCallback(
    async(slideId) => {
      setLoading(true)
      const response = await api.post(`/slide/${slideId}/describe`)
      if (response.ok) {
        AppToaster.show({
          message:
            "Task enqueued. You'll receive an email with the results when completed.",
          intent: 'success',
          icon: 'tick-circle',
        })
      } else {
        console.log(response)
        const message = `Error queuing describe segmentation (${response.status} - ${response?.body?.error})`
        AppToaster.show({
          message,
          intent: 'danger',
          icon: 'error',
        })
      }
    },
    [api, setLoading]
  )

  const renameSlide = useCallback(
    async (slideId, newName) => {
      setLoading(true)
      const response = await api.put(`/slide/${slideId}`, { name: newName })
      if (response.ok) {
        const newReportVersion = response.body
        const oldReports = currentSurvey.reports
        setCurrentSurvey({
          ...currentSurvey,
          reports: oldReports.map(r => {
            if (r.id === newReportVersion.id) {
              return newReportVersion
            } else {
              return r
            }
          }),
        })
        setLoading(false)
        return newReportVersion
      } else {
        console.log(response)
        const message = `Error renaming (${response.status} - ${response?.body?.error})`
        AppToaster.show({
          message,
          intent: 'danger',
          icon: 'error',
        })
      }
      setLoading(false)
    },
    [api, currentSurvey, setCurrentSurvey, setLoading]
  )

  return (
    <WindowContext.Provider
      value={{
        currentSurvey,
        setCurrentSurvey,
        setDialogOpen,
        dialogOpen,
        surveys,
        setSurveys,
        settings,
        setSettings,
        banner,
        stub,
        setBanner,
        setStub,
        loading,
        setLoading,
        addSlideToReport,
        renameSlide,
        describeSegment,
        deleteSlide,
        createReport,
      }}
    >
      {children}
    </WindowContext.Provider>
  )
}

export function useWindow() {
  const context = useContext(WindowContext)
  if (context === undefined) {
    throw new Error('useWindow must be used within a WindowProvider')
  }
  return context
}
