import { isArray } from 'lodash'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import { useApi } from './ApiProvider'
import { WindowContext } from './WindowProvider'
import { errorObjectToString } from '../ApiClient'
import { AppToaster } from '../components/toaster'
import {
  COLORS,
  DARKER_COLORS,
} from '../components/segmentationexplorer/Colors'

export const SurveyContext = createContext()

export const TAG_LIST = ['is_hidden', 'is_filter', 'is_weight']

/** Prepare variables received from API for use in the UI */
export const flattenApiVariables = variables =>
  variables.map(variable => ({
    ...variable,
    question: variable.question?.name,
    question_type: variable.question.type,
    tags: TAG_LIST.map(tag => variable[tag]),
    formulas: {
      sources: variable.dependents.map(
        vId => variables.find(({ id }) => vId === id).name
      ),
      dependents: variable.sources.map(
        vId => variables.find(({ id }) => vId === id).name
      ),
    },
    value_labels: variable.value_labels?.value_labels,
  }))

/** Get Variables Ready to be Sent to API */
export const prepareApiVariables = variables =>
  variables.map(variable => ({
    // Remove tags entry if it exists
    ...Object.fromEntries(
      Object.entries(variable).flatMap(([key, value]) =>
        key !== 'tags' ? [[key, value]] : []
      )
    ),
    // Reformat tag entry from [true, false, true] => ... is_hidden: true, etc
    ...(variable?.tags
      ? Object.fromEntries(TAG_LIST.map((tag, i) => [tag, variable.tags[i]]))
      : {}),
  }))

const EST_LOAD_TIME_PER_VARIABLE = 0.006 // seconds

export default function SurveyProvider({ children }) {
  const { setCurrentSurvey, surveys, setSurveys } = useContext(WindowContext)
  const [variables, setVariables] = useState()
  const [questions, setQuestions] = useState()
  const [maxDiffs, setMaxDiffs] = useState()
  const [prevVariables, setPrevVariables] = useState()
  const [selectedVariables, setSelectedVariables] = useState([])
  const [surveyDialogOpen, setSurveyDialogOpen] = useState()
  const [currentReportId, setCurrentReportId] = useState()
  const [weights, setWeights] = useState()
  const [subgroups, setSubgroups] = useState()
  const [nRespondents, setNRespondents] = useState(0)
  const [loading, setLoading] = useState()
  const [loadingProgress, setLoadingProgress] = useState(0)

  const [segmentationPriorities, setSegmentationPriorities] = useState()
  const [segmentSizeDistributionPriority, setSegmentSizeDistributionPriority] =
    useState()
  const [weightedAverageVariancePriority, setWeightedAverageVariancePriority] =
    useState()

  // Confirmit state
  const [confirmitSurveyId, setConfirmitSurveyId] = useState()

  const { surveyId } = useParams()
  const api = useApi()

  const getVariables = useCallback(
    ids =>
      variables
        ? isArray(ids)
          ? variables.filter(({ id }) => ids.includes(id))
          : variables.find(variable => variable.id === ids)
        : -1,
    [variables]
  )

  const updateSegmentSizeDistributionPriority = useCallback(
    async priority => {
      const response = await api.put(`/survey`, {
        id: surveyId,
        segment_size_distribution_priority: priority,
      })
      if (response.ok) {
        AppToaster.show({
          message: `Segment Size Distribution priority updated successfully.`,
          intent: 'success',
        })
        setSegmentSizeDistributionPriority(priority)
      } else {
        console.error(response.error)
      }
    },
    [api, surveyId]
  )

  const updateWeightedAverageVariancePriority = useCallback(
    async priority => {
      const response = await api.put(`/survey`, {
        id: surveyId,
        weighted_average_variance_priority: priority,
      })
      if (response.ok) {
        AppToaster.show({
          message: `Weighted Average Variance priority updated successfully.`,
          intent: 'success',
        })
        setWeightedAverageVariancePriority(priority)
      } else {
        console.error(response.error)
      }
    },
    [api, surveyId]
  )

  const renameSurvey = useCallback(
    /** Rename the current survey and update the survey list
     * @param {string} newName - New name for the survey
     * @returns {object} - The updated survey object
     * */
    async newName => {
      const response = await api.put(`/survey`, {
        id: surveyId,
        name: newName,
      })
      if (response.ok) {
        console.log('Survey renamed')
        setSurveys(prev =>
          prev.map(survey => {
            if (survey.id === parseInt(surveyId)) {
              return { ...survey, name: newName }
            }
            return survey
          })
        )
        AppToaster.show({
          message: 'Survey renamed',
          intent: 'success',
          icon: 'tick-circle',
        })
      } else {
        console.log('Error renaming survey')
        const message = errorObjectToString(response.body.messages.json)
        AppToaster.show({
          message,
          intent: 'danger',
          icon: 'error',
        })
      }
    },
    [api, surveyId, setSurveys]
  )

  const createComputedVariable = useCallback(
    /** Create a computed variable for this survey
     * @param {string} name - Name of the variable
     * @param {string} label - Label of the variable
     * @param {string} formula - Formula to compute the variable.
     * Logic involving other variables should be referenced by their variable id between backticks.
     * For example, to compute the sum of variables with ids 1 and 2, the formula would be `1` + `2`
     * @returns {object} - The computed variable object
     */
    async (name, label, formula, dates = []) => {
      const response = await api.post(
        `/survey/${surveyId}/variables/computed/create`,
        {
          name: name,
          label: label,
          formula: formula,
          survey_id: parseInt(surveyId),
          dates: dates,
        }
      )
      if (response.ok) {
        console.log('Computed variable created')
        const variable = response.body
        const question = variable.question
        const newVariable = flattenApiVariables([variable])[0]
        setQuestions(prev => [...prev, question])
        setVariables(prev => [...prev, newVariable])
        return newVariable
      } else {
        const message = errorObjectToString(response.body.messages.json)
        throw new Error(message)
      }
    },
    [api, surveyId, setVariables, setQuestions]
  )

  const modifyVariables = useCallback(
    /** Modify variables in the survey.
     * Provided a list of variables, it will update the survey with the new values.
     * @param {object[]} variableChanges - List of variables to update
     * @param {number} variableChanges[].id - Id of the variable to update
     */
    async variableChanges => {
      const response = await api.put(
        `/survey/${surveyId}/variables`,
        variableChanges
      )
      if (response.ok) {
        console.log('Variables updated')
        console.log({ avee: response.body })

        // This works because the API call returns ALL variables
        // in the survey, not just the ones that were updated
        const newVariables = flattenApiVariables(response.body)
        setVariables(newVariables)
        return newVariables
      } else {
        console.error(response.error)
        return null
      }
    },
    [api, surveyId, setVariables]
  )

  const assignNewQuestionToVariables = useCallback(
    /**
     * Set the question for a group of variables
     * @param {number[]} variableIds - List of variable ids that will
     * be assigned the new question
     * @param {string} name - Name of the new question
     * @param {string} type - Type of the new question
     *
     */
    async (name, type, variableIds) => {
      const response = await api.put(`/survey/${surveyId}/set_question`, {
        variables: variableIds,
        name: name,
        type: type,
      })
      if (response.ok) {
        console.log('Question updated')
        const question = response.body.question
        const deletedQuestions = response.body.deleted_questions.map(q => q.id)
        const isNewQuestion = response.body.is_new_question

        const newVariables = variables.map(old_v => {
          const new_v = question.variables.find(({ id }) => old_v.id === id)
          if (new_v) {
            new_v.question = {
              name: question.name,
              type: question.type,
            }
            return flattenApiVariables([new_v])[0]
          }
          return old_v
        })

        setVariables(newVariables)

        if (isNewQuestion) {
          setQuestions(prev => [
            ...prev.filter(q => !deletedQuestions.includes(q.id)),
            question,
          ])
        } else {
          setQuestions(prev =>
            prev.map(q => {
              if (q.id === question.id) {
                return question
              }
              return q
            })
          )
        }
        return question
      } else {
        console.log(response)
        const message = Object.entries(response.body.messages.json)
          .map(([field, errors]) => `${field}: ${errors.join(', ')}`)
          .join(', ')
        AppToaster.show({
          message,
          intent: 'danger',
          icon: 'error',
        })
      }
    },
    [api, surveyId, setVariables, setQuestions, variables]
  )

  useEffect(() => {
    ;(async () => {
      if (surveyId === undefined) {
        return
      }
      setLoading(true)
      setLoadingProgress(0)
      let elapsed_time = 0
      const num_variables = surveys?.find(
        survey => `${survey.id}` === surveyId
      )?.num_variables
      const estimated_wait = parseInt(
        EST_LOAD_TIME_PER_VARIABLE * num_variables
      )
      const setProgressInterval = setInterval(() => {
        let new_progress
        if (estimated_wait - elapsed_time > 0.5) {
          elapsed_time += 0.25
          new_progress = elapsed_time / estimated_wait
        }
        setLoadingProgress(new_progress)
      }, 250)
      const response = await api.get(`/survey/${surveyId}`)
      if (response.ok) {
        const survey = response.body
        const variables = flattenApiVariables(survey.variables)
        setVariables(variables)
        setPrevVariables(variables)
        setQuestions(survey.questions)
        setMaxDiffs(survey.maxdiffs)
        setNRespondents(survey.n)
        setCurrentSurvey(
          Object.fromEntries(
            Object.keys(survey)
              .filter(k => k !== 'variables')
              .map(k => [k, survey[k]])
          )
        )
        setConfirmitSurveyId(survey?.confirmit_survey_id)
        setWeights(survey?.weight)
        setCurrentReportId(
          survey?.reports?.[0] !== undefined ? survey.reports[0].id : null
        )
        setSegmentSizeDistributionPriority(
          survey?.segment_size_distribution_priority
        )
        setWeightedAverageVariancePriority(
          survey?.weighted_average_variance_priority
        )

        // Add colors to segmentation priorities
        const segmentationPrioritiesWithColors =
          survey?.segmentation_priorities.map((priority, index) => ({
            ...priority,
            color: COLORS[index % COLORS.length],
            darker_color: DARKER_COLORS[index % COLORS.length],
          }))

        setSegmentationPriorities(segmentationPrioritiesWithColors)
      } else {
        console.error(response.error)
        setVariables()
        setPrevVariables()
        setCurrentSurvey()
        setMaxDiffs()
        setQuestions()
        setWeights()
        setCurrentReportId()
        setSegmentSizeDistributionPriority()
        setWeightedAverageVariancePriority()
        setSegmentationPriorities()
      }
      setLoading(false)
      setLoadingProgress(0)
      clearInterval(setProgressInterval)
    })()
    return () => {
      setVariables()
      setQuestions()
      setMaxDiffs()
      setPrevVariables()
      setSelectedVariables([])
      setSurveyDialogOpen()
      setCurrentReportId()
      setWeights()
      setSubgroups()
      setNRespondents(0)
      setLoading()
      setLoadingProgress(0)
    }
  }, [api, setCurrentSurvey, surveyId, surveys])

  return (
    <SurveyContext.Provider
      value={{
        surveyId,
        questions,
        setQuestions,
        getVariables,
        variables,
        setVariables,
        prevVariables,
        setPrevVariables,
        selectedVariables,
        setSelectedVariables,
        weights,
        setWeights,
        subgroups,
        setSubgroups,
        surveyDialogOpen,
        setSurveyDialogOpen,
        maxDiffs,
        setMaxDiffs,
        nRespondents,
        currentReportId,
        setCurrentReportId,
        loading,
        loadingProgress,
        confirmitSurveyId,
        createComputedVariable,
        modifyVariables,
        assignNewQuestionToVariables,
        renameSurvey,
        // createReport,

        // Segmentation Priorities
        segmentationPriorities,
        setSegmentationPriorities,
        segmentSizeDistributionPriority,
        updateSegmentSizeDistributionPriority,
        weightedAverageVariancePriority,
        updateWeightedAverageVariancePriority,
      }}
    >
      {children}
    </SurveyContext.Provider>
  )
}

export function useSurvey() {
  const context = useContext(SurveyContext)
  if (context === undefined) {
    throw new Error('useSurvey must be used within a SurveyProvider')
  }
  return context
}
