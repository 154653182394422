import { React, useState } from 'react'
import { Button, NonIdealState, Spinner } from '@blueprintjs/core'
import { Table2, Column, Cell } from '@blueprintjs/table'
import { Tooltip2 } from '@blueprintjs/popover2'
import { useSegmentationPriorities } from '../../contexts/SegmentationPrioritiesProvider'
import { HelpDrawerSegmentationsScoresTable } from './HelpDrawer'

// Utility function to determine background color for a value
/**
 * Returns a background color string based on the given value.
 * @param {number} value - The value to evaluate.
 * @returns {string} A hex color code.
 */
function getColorForValue(value) {
  if (value >= 70) return '#6aa84f'
  if (value >= 50) return '#b6d7a8'
  if (value >= 30) return '#ffe599'
  if (value >= 15) return '#ea9999'
  return '#e06666'
}

/**
 * SegmentationsScoreTable renders a table displaying segmentation scores.
 * @param {Object} props - Component props.
 * @param {Array} props.segmentationScores - Array of segmentation score objects.
 * @returns {JSX.Element|null} The rendered table element or null if no scores.
 */
function SegmentationsScoreTable({ segmentationScores }) {
  if (!segmentationScores || segmentationScores.length === 0) return null

  // Sort segmentation scores in descending order and extract headers.
  const sortedScores = [...segmentationScores].sort(
    (a, b) => b.segmentation_score - a.segmentation_score
  )
  const priorities = Object.keys(sortedScores[0].dataframe || {})
  const columns = sortedScores.map(score => score.name)
  const headers = ['Priority', 'Weight', ...columns]

  // Build rows for each priority.
  const rows = priorities.map(priority => [
    priority,
    sortedScores[0].dataframe[priority]?.priority_weight || 0,
    ...sortedScores.map(
      score =>
        (score.dataframe[priority]?.final_differentiator_score * 100)?.toFixed(
          2
        ) || 'N/A'
    ),
  ])

  // Append segmentation score row.
  const segmentationScoreRow = [
    'Segmentation Score',
    '---',
    ...sortedScores.map(
      score => (score.segmentation_score * 100)?.toFixed(2) || 'N/A'
    ),
  ]
  rows.push(segmentationScoreRow)

  return (
    <div style={{ textAlign: 'center' }}>
      <h2>Final Segmentations Score Table</h2>
      <Table2
        numRows={rows.length}
        enableRowHeader={false}
        enableColumnResizing={true}
        defaultRowHeight={40}
        numFrozenColumns={2}
        style={{ tableLayout: 'auto', width: '100%', margin: '0 auto' }}
      >
        {headers.map((header, colIndex) => (
          <Column
            key={header}
            name={<div style={{ textAlign: 'center' }}>{header}</div>}
            cellRenderer={rowIndex => {
              const cellValue = rows[rowIndex][colIndex]
              const isNumeric =
                typeof cellValue === 'number' ||
                (typeof cellValue === 'string' &&
                  /^[+-]?(\d+(\.\d+)?)$/.test(cellValue))
              const numericValue = isNumeric ? parseFloat(cellValue) : NaN
              const cellStyle = {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor:
                  header === 'Weight'
                    ? '#E4E0E1'
                    : isNumeric
                    ? getColorForValue(numericValue)
                    : undefined,
                color: isNumeric ? 'black' : undefined,
              }
              // For 'Weight', do not append '%'
              const cellContent =
                header === 'Weight'
                  ? cellValue
                  : isNumeric
                  ? numericValue + ' %'
                  : cellValue
              return <Cell style={cellStyle}>{cellContent}</Cell>
            }}
          />
        ))}
      </Table2>
      <p style={{ marginTop: '10px', fontStyle: 'italic', color: 'grey' }}>
        NOTE: Segmentations that do not have the minimum number of respondents
        (n &lt; 100) or are not well distributed among segments will have a
        segmentation score = 0.
      </p>
    </div>
  )
}

/**
 * SegmentationsScoresTable displays segmentation scores with a button to fetch scores.
 * @returns {JSX.Element} The rendered component.
 */
export function SegmentationsScoresTable() {
  const {
    segmentationsScores,
    needsRecalculationFlag,
    fetchSegmentationsScores,
    isFetchingScores,
  } = useSegmentationPriorities()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const handleDrawerOpen = () => setIsDrawerOpen(true)
  const handleDrawerClose = () => setIsDrawerOpen(false)

  const fetchScores = async () => {
    await fetchSegmentationsScores()
  }

  return (
    <div>
      {isFetchingScores ? (
        <NonIdealState
          icon={<Spinner intent="primary" />}
          title="Fetching Segmentation Scores"
          description="Please wait while the segmentation scores are being retrieved."
        />
      ) : (
        <>
          {segmentationsScores && segmentationsScores.length > 0 ? (
            <SegmentationsScoreTable segmentationScores={segmentationsScores} />
          ) : (
            <>
              <p style={{ marginTop: '20px', fontStyle: 'italic' }}>
                No data available. Click the button to fetch scores.
              </p>
              <p
                style={{
                  marginTop: '20px',
                  color: '#5f6b7c',
                  fontStyle: 'italic',
                }}
              >
                Please ensure the following criteria are met:
              </p>{' '}
              <ul style={{ color: '#5f6b7c', fontStyle: 'italic' }}>
                <li>At least one segmentation priority must exist.</li>
                <li>
                  At least one segmentation priority must have a weight greater
                  than zero.
                </li>
              </ul>
            </>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '50px',
            }}
          >
            <Button
              intent="primary"
              icon="th"
              onClick={fetchScores}
              text="Get Segmentations Scores"
              disabled={!needsRecalculationFlag || isFetchingScores}
            />
            <Tooltip2 content="How it works" placement="bottom">
              <Button
                icon="help"
                minimal={true}
                small={true}
                onClick={handleDrawerOpen}
                style={{ marginLeft: '5px' }}
              />
            </Tooltip2>
          </div>
        </>
      )}
      <HelpDrawerSegmentationsScoresTable
        isOpen={isDrawerOpen}
        onClose={handleDrawerClose}
      />
    </div>
  )
}
