import React, { useState } from 'react'
import {
  Alert,
  Card,
  Button,
  NumericInput,
  EditableText,
  Text,
} from '@blueprintjs/core'
import { useSurvey } from '../../contexts/SurveyProvider'
import { useSegmentationPriorities } from '../../contexts/SegmentationPrioritiesProvider'
import { AppToaster } from '../toaster'
import { HelpDrawerSegmentationsPriorities } from './HelpDrawer'

/**
 * Renders the fixed face plate for segment size distribution.
 *
 * @returns {JSX.Element} The rendered face plate.
 */
function SegmentSizeDistributionFacePlate() {
  const {
    segmentSizeDistributionPriority,
    updateSegmentSizeDistributionPriority,
  } = useSurvey()
  const { setNeedsRecalculationFlag } = useSegmentationPriorities()
  const handleWeightChange = value => {
    updateSegmentSizeDistributionPriority(value)
    setNeedsRecalculationFlag(true)
  }
  return FixedPriorityFacePlate({
    color: 'grey',
    name: 'Segment Size Distribution',
    weight: segmentSizeDistributionPriority,
    onWeightChange: handleWeightChange,
  })
}

/**
 * Renders the fixed face plate for weighted variance.
 *
 * @returns {JSX.Element} The rendered face plate.
 */
function WeightedAverageVarianceFacePlate() {
  const {
    weightedAverageVariancePriority,
    updateWeightedAverageVariancePriority,
  } = useSurvey()
  const { setNeedsRecalculationFlag } = useSegmentationPriorities()
  const handleWeightChange = value => {
    updateWeightedAverageVariancePriority(value)
    setNeedsRecalculationFlag(true)
  }
  return FixedPriorityFacePlate({
    color: 'grey',
    name: 'Weighted Average Variance',
    weight: weightedAverageVariancePriority,
    onWeightChange: handleWeightChange,
  })
}

/**
 * Renders a fixed priority face plate with a numeric input.
 *
 * @param {Object} props
 * @param {string} props.color - Background color.
 * @param {string} props.name - Priority name.
 * @param {number} props.weight - Priority weight.
 * @param {Function} props.onWeightChange - Callback when weight changes.
 * @returns {JSX.Element} The rendered face plate.
 */
function FixedPriorityFacePlate({ color, name, weight, onWeightChange }) {
  return (
    <div className="weight-input-wrapper">
      <div className="weight-label">
        <Text>{name}</Text>
      </div>
      <div
        className={`weight-input-container`}
        style={{ backgroundColor: color }}
      >
        <NumericInput
          value={weight}
          onValueChange={onWeightChange}
          min={0}
          stepSize={1}
          allowNumericCharactersOnly={true}
          fill={true}
          className="weight-numeric-input"
        />
      </div>
    </div>
  )
}

/**
 * Renders a face plate for a priority with options to edit, add, remove inputs, and update weight.
 *
 * @param {Object} props
 * @param {Object} props.priority - The priority object.
 * @param {Array} props.selectedInputs - Currently selected inputs.
 * @returns {JSX.Element} The rendered PriorityFacePlate.
 */
function PriorityFacePlate({ priority, selectedInputs }) {
  const { updateSegmentationPriority } = useSegmentationPriorities()
  const [name, setName] = useState(priority.name)

  const handlePriorityNameChange = () => {
    // Only update if the name has changed
    if (name !== priority.name) {
      updateSegmentationPriority(priority.id, { name })
    }
  }

  return (
    <div className="weight-input-wrapper">
      <div className="weight-label">
        <EditableText
          value={name}
          onChange={setName}
          onConfirm={handlePriorityNameChange}
        />
      </div>
      <div
        className={`weight-input-container`}
        style={{ backgroundColor: priority.color }}
      >
        <AddInputsToPriorityButton
          selectedInputs={selectedInputs}
          priority={priority}
        />
        <RemoveInputsFromPriorityButton
          selectedInputs={selectedInputs}
          priority={priority}
        />
        <NumericInput
          value={priority.weight}
          onValueChange={value =>
            updateSegmentationPriority(priority.id, { weight: value })
          }
          min={0}
          stepSize={1}
          max={100}
          allowNumericCharactersOnly={true}
          fill={true}
          className="weight-numeric-input"
        />
        <DeletePriorityButton priority={priority} />
      </div>
    </div>
  )
}

/**
 * Renders a dummy face plate for creating a new priority.
 *
 * @param {Object} props
 * @param {Array} props.selectedInputs - Currently selected inputs.
 * @returns {JSX.Element} The rendered DummyPriorityFacePlate.
 */
function DummyPriorityFacePlate({ selectedInputs }) {
  const { createSegmentationPriority } = useSegmentationPriorities()
  const [name, setName] = useState('Dummy Priority')
  const [weight, setWeight] = useState(0)
  const [dummyCreated, setDummyCreated] = useState(false)

  const handleCreateDummyPriority = () => {
    setDummyCreated(true)
  }

  const handleCreatePriorityFromDummy = () => {
    if (selectedInputs.length > 0) {
      // Creating the new priority
      createSegmentationPriority(name, weight, selectedInputs)
      setDummyCreated(false)
    } else {
      AppToaster.show({
        message: 'Please select at least one input to save the priority',
        intent: 'warning',
      })
    }
  }

  return (
    <div className="weight-input-wrapper">
      {!dummyCreated ? (
        <Button
          text="Add New Priority"
          onClick={handleCreateDummyPriority}
          style={{ margin: '5px' }}
        />
      ) : (
        <>
          <div className="weight-label">
            <EditableText value={name} onChange={setName} />
          </div>
          <div
            className="weight-input-container"
            style={{ backgroundColor: 'grey' }}
          >
            <Button
              icon="style"
              onClick={handleCreatePriorityFromDummy}
              minimal={false}
            />
            <NumericInput
              value={weight}
              onValueChange={setWeight}
              min={0}
              stepSize={1}
              max={100}
              allowNumericCharactersOnly={true}
              fill={true}
              className="weight-numeric-input"
            />
          </div>
        </>
      )}
    </div>
  )
}

/**
 * Renders a button to add inputs to a priority.
 *
 * @param {Object} props
 * @param {Object} props.priority - The priority object.
 * @param {Array} props.selectedInputs - Currently selected inputs.
 * @returns {JSX.Element} The rendered button.
 */
function AddInputsToPriorityButton({ priority, selectedInputs }) {
  const { addInputsToPriority } = useSegmentationPriorities()
  return (
    <Button
      icon="plus"
      minimal={false}
      onClick={() => addInputsToPriority(selectedInputs, priority.id)}
      className="weight-button"
    />
  )
}

/**
 * Renders a button to remove inputs from a priority.
 *
 * @param {Object} props
 * @param {Object} props.priority - The priority object.
 * @param {Array} props.selectedInputs - Currently selected inputs.
 * @returns {JSX.Element} The rendered button.
 */
function RemoveInputsFromPriorityButton({ priority, selectedInputs }) {
  const { removeInputsFromPriority } = useSegmentationPriorities()
  return (
    <Button
      icon="minus"
      minimal={false}
      onClick={() => removeInputsFromPriority(selectedInputs, priority.id)}
      className="weight-button"
    />
  )
}

/**
 * Renders a button to delete a priority with a confirmation alert.
 *
 * @param {Object} props
 * @param {Object} props.priority - The priority to delete.
 * @returns {JSX.Element} The rendered DeletePriorityButton.
 */
function DeletePriorityButton({ priority }) {
  const { deleteSegmentationPriority } = useSegmentationPriorities()
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const handleDelete = () => {
    deleteSegmentationPriority(priority.id)
    setIsAlertOpen(false)
  }
  return (
    <>
      <Button
        icon="trash"
        minimal={false}
        onClick={() => setIsAlertOpen(true)}
        className="weight-button"
      />
      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        icon="trash"
        intent="danger"
        isOpen={isAlertOpen}
        onCancel={() => setIsAlertOpen(false)}
        onConfirm={handleDelete}
      >
        <p>Are you sure you want to delete this segmentation priority?</p>
      </Alert>
    </>
  )
}

/**
 * Renders a card for configuring priority weights.
 *
 * @param {Object} props
 * @param {Array} props.selectedInputs - Currently selected inputs.
 * @returns {JSX.Element} The rendered WeightsConfigurationCard.
 */
export function WeightsConfigurationCard({ selectedInputs }) {
  const { segmentationPriorities } = useSegmentationPriorities()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const handleDrawerOpen = () => setIsDrawerOpen(true)
  const handleDrawerClose = () => setIsDrawerOpen(false)

  return (
    <Card className="weights-card-container">
      <div
        className="weights-section"
        style={{ maxHeight: '500px', overflowY: 'auto' }}
      >
        <h3>
          Segmentations Priorities{' '}
          <Button icon="help" minimal={true} onClick={handleDrawerOpen} />
        </h3>
        <SegmentSizeDistributionFacePlate />
        <WeightedAverageVarianceFacePlate />
        {segmentationPriorities.map(priority => (
          <PriorityFacePlate
            key={priority.id}
            priority={priority}
            selectedInputs={selectedInputs}
          />
        ))}
        <DummyPriorityFacePlate selectedInputs={selectedInputs} />
      </div>
      <HelpDrawerSegmentationsPriorities
        isOpen={isDrawerOpen}
        onClose={handleDrawerClose}
      />
    </Card>
  )
}
