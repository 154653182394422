import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react'
import { useApi } from './ApiProvider'
import { errorObjectToString } from '../ApiClient'
import { AppToaster } from '../components/toaster'
import { useSurvey } from './SurveyProvider'
import {
  COLORS,
  DARKER_COLORS,
} from '../components/segmentationexplorer/Colors'

const SegmentationPrioritiesContext = createContext()

/**
 * SegmentationPrioritiesProvider component provides context for segmentation priorities.
 *
 * @param {Object} props - Component props.
 * @param {React.ReactNode} props.children - Child components.
 * @returns {JSX.Element} The context provider.
 */
export function SegmentationPrioritiesProvider({ children }) {
  const [segmentations, setSegmentations] = useState()
  const [availableInputs, setAvailableInputs] = useState()
  const [needsRecalculationFlag, setNeedsRecalculationFlag] = useState(true)
  const [segmentationsScores, setSegmentationsScores] = useState([])
  const [isFetchingScores, setIsFetchingScores] = useState(false)

  const api = useApi()
  const { surveyId, segmentationPriorities, setSegmentationPriorities } =
    useSurvey()

  /**
   * Fetch segmentations from the API and update state.
   *
   * @returns {Promise<void>}
   */
  const fetchSegmentations = useCallback(async () => {
    if (api === undefined || surveyId === undefined) return
    const response = await api.get(`/survey/${surveyId}/segmentations`)
    if (response.ok) {
      setSegmentations(response.body)
    } else {
      const errorDetails =
        response.body?.messages?.json || 'An unexpected error occurred'
      const message = errorObjectToString(errorDetails)
      AppToaster.show({ message, intent: 'danger', icon: 'error' })
    }
  }, [api, surveyId])

  /**
   * Fetch segmentation priorities from the API and update state.
   *
   * @returns {Promise<void>}
   */
  const fetchSegmentationPriorities = async () => {
    const response = await api.get(
      `/survey_segmentation_priorities/${surveyId}`
    )
    if (response.ok) {
      setSegmentationPriorities(response.body)
    } else {
      const errorDetails =
        response.body?.messages?.json || 'An unexpected error occurred'
      const message = errorObjectToString(errorDetails)
      AppToaster.show({ message, intent: 'danger', icon: 'error' })
    }
  }

  /**
   * Create a new segmentation priority.
   *
   * @param {string} priorityName - The name of the priority.
   * @param {number} weight - The weight for the priority.
   * @param {Array} selectedInputs - The inputs selected for the priority.
   * @returns {Promise<void>}
   */
  const createSegmentationPriority = async (
    priorityName,
    weight,
    selectedInputs
  ) => {
    if (!priorityName || selectedInputs.length === 0) {
      AppToaster.show({
        message: 'Name, weight, and at least one input are required.',
        intent: 'warning',
      })
      return
    }

    const inputIds = selectedInputs.map(input => input.id)
    const data = {
      name: priorityName,
      weight: weight || 0,
      input_ids: inputIds,
    }

    const response = await api.post('/segmentation_priority', data)
    if (response.ok) {
      AppToaster.show({
        message: `Segmentation priority created successfully.`,
        intent: 'success',
      })

      // Assign a color to the new priority
      const newPriority = response.body
      const existingPrioritiesLength = segmentationPriorities.length
      newPriority.darker_color =
        DARKER_COLORS[existingPrioritiesLength % DARKER_COLORS.length]
      newPriority.color = COLORS[existingPrioritiesLength % COLORS.length]

      // Update the priorities list
      setSegmentationPriorities(prev => [...prev, newPriority])
      setNeedsRecalculationFlag(true)
    } else {
      AppToaster.show({
        message: `Failed to create segmentation priority. Server responded with status ${response.status}.`,
        intent: 'danger',
      })
    }
  }

  /**
   * Fetch all available inputs for the survey.
   *
   * @returns {Promise<void>}
   */
  const fetchAllAvailableInputs = async () => {
    if (api === undefined || surveyId === undefined) return
    const response = await api.get(
      `/survey/${surveyId}/questions?types=single-select&types=multi-select&types=number`
    )
    if (response.ok) {
      const questions = response.body.questions
      const inputs = questions.flatMap(question =>
        question.variables
          .filter(
            variable =>
              variable.name !== 'respid' && variable.name !== 'responseid'
          ) // Filter out 'respid' and 'responseid'
          .map(variable => ({
            id: variable.id,
            name: variable.name,
            label: variable.label,
            priorityId: variable.segmentation_priority_id,
          }))
      )

      const inputsMap = new Map()
      inputs.forEach(input => {
        inputsMap.set(input.id, input)
      })
      setAvailableInputs(Array.from(inputsMap.values()))
    } else {
      const errorDetails =
        response.body?.messages?.json || 'An unexpected error occurred'
      const message = errorObjectToString(errorDetails)
      AppToaster.show({ message, intent: 'danger', icon: 'error' })
    }
  }

  /**
   * Update a segmentation priority with new data.
   *
   * @param {number|string} id - The identifier of the priority.
   * @param {Object} newData - The new data for the priority.
   * @returns {Promise<void>}
   */
  const updateSegmentationPriority = useCallback(
    async (id, newData) => {
      const response = await api.put(`/segmentation_priority/${id}`, newData)
      if (response.ok) {
        AppToaster.show({
          message: `Segmentation priority updated successfully.`,
          intent: 'success',
        })

        // Update the priorities list
        setSegmentationPriorities(prev =>
          prev.map(p => (p.id === id ? { ...p, ...newData } : p))
        )
        setNeedsRecalculationFlag(true)
      } else {
        const message = errorObjectToString(response.body.messages.json)
        AppToaster.show({
          message: message,
          intent: 'danger',
        })
      }
    },
    [api, setSegmentationPriorities]
  )

  /**
   * Add inputs to an existing segmentation priority.
   *
   * @param {Array} inputs - The inputs to add.
   * @param {number|string} priority_id - The identifier of the priority.
   * @returns {Promise<void>}
   */
  const addInputsToPriority = useCallback(
    async (inputs, priority_id) => {
      if (inputs.length === 0) {
        AppToaster.show({
          message: 'At least one input is required.',
          intent: 'warning',
        })
        return
      }

      // Go fetch the inputs of this priority
      const priority = segmentationPriorities.find(p => p.id === priority_id)
      const old_input_ids = availableInputs
        .filter(input => input.priorityId === priority_id)
        .map(input => input.id)
      const new_input_ids = inputs.map(input => input.id)
      updateSegmentationPriority(priority_id, {
        input_ids: [...old_input_ids, ...new_input_ids],
      })
    },
    [segmentationPriorities, availableInputs, updateSegmentationPriority]
  )

  /**
   * Remove inputs from an existing segmentation priority.
   *
   * @param {Array} inputs - The inputs to remove.
   * @param {number|string} priority_id - The identifier of the priority.
   * @returns {Promise<void>}
   */
  const removeInputsFromPriority = useCallback(
    async (inputs, priority_id) => {
      if (inputs.length === 0 || !inputs.some(input => input.priorityId)) {
        AppToaster.show({
          message: 'At least one input with priority is required .',
          intent: 'warning',
        })
        return
      }

      if (!inputs.every(input => input.priorityId === priority_id)) {
        AppToaster.show({
          message: 'You should select inputs with the same priority.',
          intent: 'warning',
        })
        return
      }

      // Go fetch the inputs of this priority
      const priority = segmentationPriorities.find(p => p.id === priority_id)
      const old_input_ids = availableInputs
        .filter(input => input.priorityId === priority_id)
        .map(input => input.id)
      const new_input_ids = inputs.map(input => input.id)
      updateSegmentationPriority(priority_id, {
        input_ids: old_input_ids.filter(id => !new_input_ids.includes(id)),
      })
    },
    [segmentationPriorities, availableInputs, updateSegmentationPriority]
  )

  /**
   * Delete a segmentation priority.
   *
   * @param {number|string} id - The identifier of the priority to delete.
   * @returns {Promise<void>}
   */
  const deleteSegmentationPriority = async id => {
    const response = await api.delete(`/segmentation_priority/${id}`)
    if (response.ok) {
      AppToaster.show({
        message: `Segmentation priority deleted successfully.`,
        intent: 'success',
      })

      // Get the current priorities and remove the deleted one
      const currentPriorities = segmentationPriorities
      const deletedIndex = currentPriorities.findIndex(p => p.id === id)
      const updatedPriorities = currentPriorities.filter(p => p.id !== id)

      // If the deleted priority was not the last one, update the colors
      if (deletedIndex !== currentPriorities.length - 1) {
        updatedPriorities.forEach((priority, index) => {
          const newColor = COLORS[index % COLORS.length]
          const newDarkerColor = DARKER_COLORS[index % DARKER_COLORS.length]
          // Update the color if it has changed
          if (
            priority.color !== newColor ||
            priority.darker_color !== newDarkerColor
          ) {
            priority.color = newColor
            priority.darker_color = newDarkerColor
          }
        })
      }
      setSegmentationPriorities(updatedPriorities)
      setNeedsRecalculationFlag(true)
    } else {
      AppToaster.show({
        message: `Failed to delete segmentation priority. Server responded with status ${response.status}.`,
        intent: 'danger',
      })
    }
  }

  /**
   * Fetch segmentation scores from the API and update state.
   *
   * @returns {Promise<void>}
   */
  const fetchSegmentationsScores = useCallback(async () => {
    setIsFetchingScores(true)
    const response = await api.get(
      `/survey/${surveyId}/segmentations_scores_table`
    )

    if (response.ok) {
      const scoreData = response.body.score_data

      setSegmentationsScores(scoreData)
      // Reset the flag to indicate the scores are now up-to-date.
      setNeedsRecalculationFlag(false)
    } else {
      console.error('API response error:', response.problem || 'Unknown error')
    }
    setIsFetchingScores(false)
  }, [api, surveyId, setSegmentationsScores])

  useEffect(() => {
    if (surveyId === undefined) return
    fetchSegmentations()
    fetchSegmentationPriorities()
  }, [api, surveyId])

  useEffect(() => {
    if (surveyId === undefined) return
    fetchAllAvailableInputs()
  }, [api, surveyId, segmentationPriorities])

  return (
    <SegmentationPrioritiesContext.Provider
      value={{
        segmentations,
        segmentationPriorities,
        availableInputs,
        needsRecalculationFlag,
        setNeedsRecalculationFlag,
        segmentationsScores,
        setSegmentationsScores,
        createSegmentationPriority,
        updateSegmentationPriority,
        deleteSegmentationPriority,
        addInputsToPriority,
        removeInputsFromPriority,
        fetchSegmentationsScores,
        isFetchingScores,
      }}
    >
      {children}
    </SegmentationPrioritiesContext.Provider>
  )
}

export const useSegmentationPriorities = () => {
  const context = useContext(SegmentationPrioritiesContext)
  if (!context) {
    throw new Error(
      'useSegmentationPriorities must be used within a SegmentationPrioritiesProvider'
    )
  }
  return context
}
