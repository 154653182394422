import React from 'react'
import { Drawer, Position, Divider, Button } from '@blueprintjs/core'

/**
 * HelpDrawerSegmentationPriorities component provides a detailed explanation of the segmentation priorities workflow.
 *
 * @param {boolean} isOpen - Indicates whether the drawer is open.
 * @param {function} onClose - Function to call when closing the drawer.
 * @returns {JSX.Element} The rendered help drawer.
 */
export function HelpDrawerSegmentationsPriorities({ isOpen, onClose }) {
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      position={Position.RIGHT}
      title="How Segmentation Priorities Work"
      style={{ overflowY: 'auto' }}
    >
      <div className="help-drawer-content" style={{ padding: '1rem' }}>
        <h3 style={{ marginBottom: '0.5rem' }}>
          1. Fixed Priorities Configuration
        </h3>
        <p style={{ marginBottom: '1rem' }}>
          Configure the Fixed Priorities:{' '}
          <strong>Segment Size Distribution</strong> and{' '}
          <strong>Weighted Average Variance </strong>.
        </p>
        <img
          src="/fixed-priorities-faceplate.png"
          alt="Fixed Priorities Diagram"
          style={{
            display: 'block',
            margin: 'auto',
            marginBottom: '1rem',
          }}
        />
        <p style={{ marginBottom: '1rem' }}>
          To modify these values:
          <br />
          <strong>Keyboard interactions:</strong> Use ↑/↓ for one step (default:
          ±1), Shift + ↑/↓ for a major step (default: ±10), and Alt + ↑/↓ for a
          minor step (default: ±0.1).
          <br />
          <strong>Mouse interactions:</strong> Click ⌃/⌄ for one step, Shift +
          Click ⌃/⌄ for a major step, and Alt + Click ⌃/⌄ for a minor step.
        </p>

        <ul>
          <strong>Note:</strong>
          <li>
            <strong>Segment Size Distribution</strong>: It measures how evenly
            the segments (or clusters) are distributed. A balanced distribution
            indicates that segments are well represented, minimizing bias in
            segmentation.
          </li>
          <li>
            <strong>Weighted Average Variance</strong>: this metric reflects the
            weighted spread of variance across segments. It helps determine the
            distinctiveness and reliability of each segment.
          </li>
        </ul>
        <Divider />
        <h3 style={{ marginBottom: '0.5rem' }}>
          2. Segmentations Priorities Configuration
        </h3>
        <p style={{ marginBottom: '1rem' }}>
          Change the name using the <code style={{ color: 'blue' }}>Name</code>{' '}
          and adjust the weight using the{' '}
          <code style={{ color: 'blue' }}>Weight value</code>.
        </p>
        <img
          src="/segmentation-priority-faceplate.png"
          alt="Segmentation Priority Diagram"
          style={{
            display: 'block',
            margin: 'auto',
            marginBottom: '1rem',
          }}
        />
        <div style={{ marginBottom: '1rem' }}>
          <p style={{ marginBottom: '0.5rem' }}>How the icon buttons work:</p>
          <p style={{ marginBottom: '0.5rem' }}>
            <Button icon="plus" small={true} /> Add selected inputs to the
            priority.
          </p>
          <p style={{ marginBottom: '0.5rem' }}>
            <Button icon="minus" small={true} /> Drop selected inputs from the
            priority.
          </p>
          <p style={{ marginBottom: '0.5rem' }}>
            <Button icon="trash" small={true} /> Delete the priority.
          </p>
        </div>
        <Divider />
        <h3 style={{ marginBottom: '0.5rem' }}>3. Creating a Dummy Priority</h3>
        <p style={{ marginBottom: '1rem' }}>
          By clicking the <Button text="Add New Priority" small={true} />{' '}
          button, a <em>dummy priority</em> is initially created. To visualize
          this step, see the image below:
        </p>
        <img
          src="/dummy-priority-faceplate.png"
          alt="Dummy Priority Diagram"
          style={{
            display: 'block',
            margin: 'auto',
            marginBottom: '1rem',
          }}
        />
        <p style={{ marginBottom: '1rem' }}>
          From the dummy priority, you can create a new{' '}
          <strong>Segmentation Priority</strong>, as long as at least one input
          is selected; furthermore, you can modify its name and weight before
          converting it.
        </p>
        <p>
          <strong>Important:</strong> You can create only one dummy priority at
          a time. To create a new dummy priority, you must first convert the
          existing dummy priority to a Segmentation Priority.
          <br />
          <br />
          How to convert a dummy priority to a Segmentation Priority:
          <ol>
            <li>Select the input(s) for the dummy priority.</li>
            <br />
            <li>
              Click the <Button icon="style" small={true} /> button. If we don't
              select any input, a warning will be shown.
            </li>
          </ol>
        </p>
        <Divider />
        <h3 style={{ marginBottom: '0.5rem' }}>4. Input Selection Methods</h3>
        <p style={{ marginBottom: '1rem' }}>
          When adding or removing inputs to a segmentation priority (dummy or
          permanent), you have multiple ways to select inputs:
          <br />
          <ul>
            <li>
              Hold{' '}
              <Button style={{ fontWeight: 'bold' }} small={true}>
                ⌃ Ctrl
              </Button>{' '}
              and click to add inputs individually.
            </li>
            <br />

            <li>
              Hold{' '}
              <Button style={{ fontWeight: 'bold' }} small={true}>
                ⇧ Shift
              </Button>{' '}
              and click to select a range.
            </li>
          </ul>
        </p>
      </div>
    </Drawer>
  )
}

/**
 * HelpDrawer component provides information about the Silhouette Plot.
 *
 * @returns {JSX.Element} The rendered help drawer.
 */
export function HelpDrawerSilhouettePlot({ isOpen, onClose }) {
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      position={Position.RIGHT}
      title="What is a Silhouette Plot?"
    >
      <div className="help-drawer-content">
        <p>
          A Silhouette Plot is a graphical representation used to interpret and
          validate the consistency within clusters of data. It measures how
          similar an object is to its own cluster (cohesion) compared to other
          clusters (separation).
        </p>
        <h4>Understanding the Plot</h4>
        <ul>
          <li>
            <strong>Silhouette Coefficient:</strong> Ranges from -1 to 1. A high
            value indicates that the object is well matched to its own cluster
            and isolated from neighboring clusters. Silhouette scores are
            calculated using:
            <br />
            <div style={{ textAlign: 'center' }}>
              <math xmlns="http://www.w3.org/1998/Math/MathML">
                <mrow>
                  <mi>s</mi>
                  <mo>(</mo>
                  <mi>i</mi>
                  <mo>)</mo>
                  <mo>=</mo>
                  <mfrac>
                    <mrow>
                      <mi>b</mi>
                      <mo>(</mo>
                      <mi>i</mi>
                      <mo>)</mo>
                      <mo>&#x2212;</mo>
                      <mi>a</mi>
                      <mo>(</mo>
                      <mi>i</mi>
                      <mo>)</mo>
                    </mrow>
                    <mrow>
                      <mi>max</mi>
                      <mo>&#x2061;</mo>
                      <mo>(</mo>
                      <mi>a</mi>
                      <mo>(</mo>
                      <mi>i</mi>
                      <mo>)</mo>
                      <mo>,</mo>
                      <mi>b</mi>
                      <mo>(</mo>
                      <mi>i</mi>
                      <mo>)</mo>
                      <mo>)</mo>
                    </mrow>
                  </mfrac>
                </mrow>
              </math>
            </div>
            <br />
            where <em>a</em> is the mean distance between a sample and all other
            points in the same class, and <em>b</em> is the mean distance
            between a sample and all other points in the next nearest cluster.
          </li>
          <li>
            <strong>Segments:</strong> Each bar represents a data point's
            silhouette score within a segment.
          </li>
          <li>
            <strong>Average Silhouette Score:</strong> The red dashed line
            indicates the average silhouette score across all data points.
          </li>
        </ul>
        <img
          src="/plot-kmeans-silhouette-analysis.png"
          alt="Silhouette Plot Example"
          style={{ display: 'block', margin: 'auto' }}
        />
      </div>
    </Drawer>
  )
}

export function HelpDrawerSegmentationsScoresTable({ isOpen, onClose }) {
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      position={Position.RIGHT}
      title="How It Works - Segmentation Scores Table"
      style={{ overflowY: 'auto' }}
    >
      <div
        className="help-drawer-content"
        style={{ margin: '20px', padding: '1rem' }}
      >
        <p>
          This documentation provides a detailed explanation of the step-by-step
          process used in the
          <code style={{ color: 'blue' }}> Segmentation Score </code>{' '}
          calculation.
        </p>
        <Divider />

        <h4>1. Initial Validation</h4>
        <p>The segmentation must satisfy two primary conditions:</p>
        <ul>
          <li>
            <strong>Minimum Respondents Per Segment:</strong> Each segment must
            contain at least 100 respondents.
            <br />
            <br />
            <div style={{ textAlign: 'center' }}>
              <math xmlns="http://www.w3.org/1998/Math/MathML">
                <mrow>
                  <mi>min</mi>
                  <mo>(</mo>
                  <mi>count(s)</mi>
                  <mo>)</mo>
                  <mo>&ge;</mo>
                  <mn>100</mn>
                </mrow>
              </math>
            </div>
            <br />
          </li>
          <li>
            <strong>Balanced Segment Distribution:</strong> The respondent
            distribution across segments must be proportionate.
            <br />
            <br />
            <div style={{ textAlign: 'center' }}>
              <math xmlns="http://www.w3.org/1998/Math/MathML">
                <mrow>
                  <mfrac>
                    <mrow>
                      <mi>max</mi>
                      <mo>(</mo>
                      <mi>p(s)</mi>
                      <mo>)</mo>
                    </mrow>
                    <mrow>
                      <mi>min</mi>
                      <mo>(</mo>
                      <mi>p(s)</mi>
                      <mo>)</mo>
                    </mrow>
                  </mfrac>
                  <mo>&lt;</mo>
                  <mn>2.5</mn>
                </mrow>
              </math>
            </div>
            <br />
            Here, <code>p(s)</code> represents the proportion of respondents in
            segment <code>s</code>.
          </li>
        </ul>
        <p>
          If either condition is not met, the{' '}
          <em style={{ color: 'red' }}>segmentation score defaults to 0</em>.
        </p>
        <Divider />

        <h4>2. Initial Differentiation Score</h4>
        <p>
          This step assesses the segmentation's ability to distinguish groups
          based on survey variables. The function counts the number of variables
          where a segment attains the maximum index score, considering only
          those variables with an impact score greater than 1.
        </p>
        <Divider />

        <h4>3. Segment Size Distribution</h4>
        <p>
          The segmentation's balance is quantified by evaluating the
          distribution of respondents across segments.
        </p>
        <p>
          This metric is defined as the smallest proportion of respondents in
          any segment multiplied by the total number of segments. A value close
          to <strong>1</strong> indicates a well-balanced segmentation where
          each segment has an equal share of respondents.
        </p>
        <Divider />

        <h4>4. Weighted Average Variance</h4>
        <p>
          This metric measures the stability of the segmentation. The weighted
          average variance is computed as:
        </p>
        <div style={{ textAlign: 'center' }}>
          <math xmlns="http://www.w3.org/1998/Math/MathML">
            <mrow>
              <mi>weighted_average_variance</mi>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <mo>&Sigma;</mo>
                  <mrow>
                    <mi>
                      w<sub>i</sub>
                    </mi>
                    <mo>&times;</mo>
                    <mi>
                      variance<sub>i</sub>
                    </mi>
                  </mrow>
                </mrow>
                <mrow>
                  <mo>&Sigma;</mo>
                  <mi>
                    w<sub>i</sub>
                  </mi>
                </mrow>
              </mfrac>
            </mrow>
          </math>
        </div>
        <Divider />

        <h4>5. Final Segmentation Score Calculation</h4>
        <p>
          The final segmentation score is computed as a weighted sum of the
          final differentiation scores, normalized by the sum of weights:
        </p>
        <div style={{ textAlign: 'center' }}>
          <math xmlns="http://www.w3.org/1998/Math/MathML">
            <mrow>
              <mi>segmentation_score</mi>
              <mo>=</mo>
              <mfrac>
                <mrow>
                  <mo>&Sigma;</mo>
                  <mrow>
                    <mi>
                      final_differentiator_score<sub>i</sub>
                    </mi>
                    <mo>&times;</mo>
                    <mi>
                      normalized_weight<sub>i</sub>
                    </mi>
                  </mrow>
                </mrow>
                <mrow>
                  <mo>&Sigma;</mo>
                  <mi>
                    normalized_weight<sub>i</sub>
                  </mi>
                </mrow>
              </mfrac>
            </mrow>
          </math>
        </div>
        <p>
          <em>
            This final value reflects the overall quality and differentiation of
            the segmentation.
          </em>
        </p>
      </div>
    </Drawer>
  )
}
