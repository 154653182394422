import React, { useState } from 'react'
import { Tabs, Tab, Icon } from '@blueprintjs/core'
import { SegmentationExplorerWeights } from './SegmentationExplorerWeights'
import { SegmentationsScoresTable } from './SegmentationsScoresTable'
import { useSegmentationPriorities } from '../../contexts/SegmentationPrioritiesProvider'

/**
 * WeightsGroupTabs component renders a tab view that toggles between the weights configuration
 * and the segmentation scores summary.
 *
 * @returns {JSX.Element} The rendered tab component.
 */
export function WeightsGroupTabs() {
  const [selectedTab, setSelectedTab] = useState('weights')
  const { isFetchingScores } = useSegmentationPriorities()

  return (
    <Tabs
      id="weightTabs"
      selectedTabId={selectedTab}
      onChange={tabId => setSelectedTab(tabId)}
    >
      <Tab
        id="weights"
        title={
          <>
            <Icon icon="lab-test" />
            <span style={{ marginLeft: '8px' }}>Weights</span>
          </>
        }
        panel={
          <div style={{ height: '100%', overflowY: 'auto' }}>
            <SegmentationExplorerWeights />
          </div>
        }
        disabled={isFetchingScores}
      />
      <Tab
        id="summary"
        title={
          <>
            <Icon icon="info-sign" />
            <span style={{ marginLeft: '8px' }}>Summary</span>
          </>
        }
        panel={<SegmentationsScoresTable />}
      />
    </Tabs>
  )
}
