const COLORS = [
  '#FF9F7F',
  '#5470C6',
  '#91CC75',
  '#FAC858',
  '#EE6666',
  '#73C0DE',
  '#9A60B4',
  '#3BA272',
  '#FC8452',
  '#EA7CCC',
]
const DARKER_COLORS = [
  '#CC7F65',
  '#4258A1',
  '#6FA85B',
  '#C69745',
  '#B45050',
  '#5793A6',
  '#6B3A7A',
  '#2A6B4D',
  '#B35A3A',
  '#A14A8A',
]

const PLOTS_COLORS = [
  '#5470C6',
  '#91CC75',
  '#FAC858',
  '#EE6666',
  '#73C0DE',
  '#3BA272',
  '#FC8452',
  '#9A60B4',
  '#EA7CCC',
  '#FF9F7F',
]

export { COLORS, DARKER_COLORS, PLOTS_COLORS }
